/* ---------------------------- */
/* Top Bar */
/* ---------------------------- */

.top-bar{
  display: flex;
  background-color: black;
  color: #fff; 
}
.top-bar p{
  text-decoration: none;
  list-style: none;
  font-size: 20px;
  padding: 20px 60px 20px 60px;
  margin: 0px 20px 0px 10px;
  /* cursor: pointer; */
  color: rgb(0, 255, 255);
  font-weight: 600;
  border-right: 1px solid #fff;
}
.top-bar .lis{
  text-decoration: none;
  list-style: none;
  font-size: 20px;
  padding: 20px;
  margin: 0px 10px;
  cursor: pointer;
  color: #fff; 
}
.top-bar li:hover{
  background-color: #ffffff35;
}
/* ---------------------------- */
/* Top Bar */
/* ---------------------------- */




.search-div{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  border-bottom: 1px solid black;
}
.search-div div{
  margin: 20px;
  width: 100%;
}
.search-div input{
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  min-width: 300px;
  width: 80%;
  max-width: 600px;
}
.search-div label{
  padding: 10px;
  font-size: 18px;
  display: block;
}
.search-div button{
  width: 320px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: rgb(108, 191, 255);
  border-radius: 5px;
  border: 2px solid  rgb(48, 142, 214);
  margin: 0px 10px;
  font-weight: 600;
}
.search-div button:hover{
  background-color: rgb(81, 145, 194);
}
.search-div button:active{
  background-color: rgb(63, 118, 159);
}







/* ---------------------------- */
/* Login */
/* ---------------------------- */
.sign-up-main {
  padding: 110px 20px 60px 20px;
}

#sign-up {
  width: 678px;
  padding: 65px;
  margin: auto;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);

}

#sign-up h2 {
  font-family: var(--font-family-montserrat);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 60px;
}

#sign-up form>div>div {
  display: flex;
  width: 100%;
}

#sign-up form>div>div>div {
  width: 50%;
  padding: 0px 10px;
}

#sign-up form>div>.left {
  margin-right: 30px;
  margin-bottom: 30px;
}

#sign-up .form-input {
  display: flex;
  flex-direction: column;
  /* margin: 20px 0px; */
  width: 100%;
  height: 103px;
  /* padding: 15px; */
}

#sign-up .label {
  font-size: 16px;
  color: #555;
  text-transform: capitalize;
  display: block;
  margin-bottom: 5px;
}

#sign-up .input {
  line-height: 50px;
  background: #fafafa;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  color: #666;
  outline: none;
  border: none;
  margin-bottom: 22px;
}

#sign-up .gender-input {
  height: 103px;
}

#sign-up .gender-input>div {
  display: flex;
  align-items: center;
  padding: 15px 0px 0 0;
}

#sign-up .gender-radio {
  height: 20px;
  width: 20px;
}

#male {
  margin: 0px 10px 0px 0px;
}

#female {
  margin: 0px 10px 0px 60px;
}

#sign-up button {
  border-radius: 5px;
  background-color: black;
  display: inline-block;
  padding: 15px 50px;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  border: none;
}

#sign-up button:disabled {
  border-radius: 5px;
  background-color: black;
  display: inline-block;
  padding: 15px 50px;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 18px;
  color: #000000;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  border: none;
  cursor: not-allowed;
  
}

.link-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#sign-up .link {
  color: #007bff;
  transition: 0.5s;
  text-decoration: none;
}



@media only screen and (max-width: 768px) {

  #sign-up {
      width: 100%;
      padding: 50px;
  }

}

@media only screen and (max-width: 600px) {
  #sign-up {
      padding: 25px;
  }
  #sign-up h2 {
      margin-top: 20px;
      margin-bottom: 40px;
      text-align: center;
  }
  #sign-up form>div>div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  #sign-up form>div>div>div {
      width: 100%;
  }
  #sign-up button {
      margin: 10px 0px;
      width: 100%;
      padding: 15px 25px;
      font-size: 16px;
  }
  #sign-up form>div>.left {
      margin-right: 0px;
      margin-bottom: 0px;
  }


  #sign-up .gender-input {
      height: 103px;
  }
  
  #sign-up .gender-input>div {
      display: flex;
      align-items: center;
      padding: 15px 0px 0 0;
  }
  
  #sign-up .gender-radio {
      height: 15px;
      width: 15px;
  }
  
  #male {
      margin: 0px 8px 0px 0px;
  }
  
  #female {
      margin: 0px 8px 0px 30px;
  }
}
/* ---------------------------- */
/* Login */
/* ---------------------------- */



/* ---------------------------- */
/* Alert */
/* ---------------------------- */
#alert{
  position: fixed;
  width: 100%;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-montserrat);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.alert-div{
  box-sizing: border-box;
  min-height: 80px;
  min-width: 300px;
  padding: 20px ;
  background-color: #0090e0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  animation: alertAni 0.3s ease-in-out;
}
.top-bar .cred{
  color:  #ff0f0fde;
}
.red{
  background-color: #ff0f0fde;
}

.green{
  background-color: #14a148;
}

@keyframes alertAni {
  0%{
      opacity: 0;
      transform: translateY(-100px);
  }
  100%{
      opacity: 1;
  }
}
/* ---------------------------- */
/* Alert */
/* ---------------------------- */